export class UserModel {
  id: number;
  identifier: string;
  password: string;
  school: number; // school_id
  parent: number; // parent_id
  jwt: string;
  name: string;
  designation: string;
  email: string;
  phone: string;
  role: string;
  verified: boolean;
  /*[key: string]: any;*/ // to dynamically add more attributes
  updatedAt: string;
}
